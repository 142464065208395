import React from 'react'
import { List, Datagrid, TextField, EmailField,
  EditButton, DeleteButton,BooleanField,
  Edit, TextInput, BooleanInput,PasswordInput,
  Create, SimpleForm, DateInput, NumberInput, required
} from 'react-admin'

const CustomBulkActionButtons = (props) => (
  <React.Fragment>
    {/* Add any other custom bulk actions here if needed */}
  </React.Fragment>
);
const validatePort = (value) => {
  if (value < 0 || value > 65535) {
    return 'Port must be between 0 and 65535';
  }
  return undefined;
};

const validateRequired = required('This field is required');
export const MMMSIPWhiteList = () => {
  return <List pagination={false}>
    <Datagrid bulkActionButtons={<CustomBulkActionButtons />}>
      <TextField sortable={false} disabled source="id"/>
      <TextField sortable={false} source="ip"/>
      <EditButton />
      <DeleteButton/>
    </Datagrid>
  </List>
}

export const MMMSIPWhitelistEdit= () => {
  return (
    <Edit title='Edit MMS IP Whitelist' >
      <SimpleForm>
        <TextInput disabled source="id"/>
        <TextInput source="ip"/>
      </SimpleForm>
    </Edit>
  )
}

export const MMMSIPWhitelistCreate = () => {
  return (
    <Create title='Add IP to MMS Whitelist'>
      <SimpleForm>
        <TextInput source="ip" validate={validateRequired} />
      </SimpleForm>
    </Create>
  );
};